import React from 'react';
import Footer from '../../footer/Footer';
import GreebleTownHeader from '../../header/GreebleTownHeader';
import HomeSlider from './HomeSlider';
import Packs from './Packs';
import AboutInner from './AboutInner'
import WhyUs from './WhyUs'

function IndexGreebleTown(){
	return(

        <>
         {/* header-area */}
          <GreebleTownHeader/>
       
      
        {/* main-area */}
        <main>
          {/* slider-area */}
          <HomeSlider/>
          <AboutInner/>    
          <Packs/>
          
          <WhyUs/>
          {/* slider-area-end */}
        </main>
        {/* main-area-end */}
        <Footer/>
        </>
	)
}
	
export default IndexGreebleTown