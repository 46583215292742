import { BrowserRouter as Router, Switch} from 'react-router-dom';
import GreebleTown from './pages/homes/GreebleTown';
import About from './components/index/greebletown/About';
import PacksMain from './components/index/greebletown/PacksMain';
import IndustrialPack from './components/greebletownpacks/industrialpack/IndustrialPack'
import MetroPunkPack from './components/greebletownpacks/metropunk/MetroPunkPack'
import ModernCitiesPack from './components/greebletownpacks/moderncities/ModernCities'
import ScifiGreeblesPack from './components/greebletownpacks/scifi/ScifiGreebles'
import MotherboardPack from './components/greebletownpacks/motherboard/Motherboard'
import ScrollToTopRoute from './components/scrolltotop/ScrollToTopRoute';



function App() {
      //  const [loading,setLoading] = useState(false);

      //  useEffect(()=>{
      //   setLoading(true)
      //   setTimeout(()=>{
      //     setLoading(false)
      //   },8000)
      //  },[])
      //  {
      //   loading ?(
      //     <MoonLoader color={color} loading={loading} css={override} size={150} />
      //   )
         
  
      // }
  return (
    
    <div className="App">
      
     <Router>
       
        <Switch>
      
        <ScrollToTopRoute exact={true} path='/'>
          <GreebleTown/>
        </ScrollToTopRoute>
        
{/* 
        <ScrollToTopRoute exact={true} path='/industrial'>
          <IndustrialPack/>
        </ScrollToTopRoute>
        <ScrollToTopRoute exact={true} path='/metropunk'>
          <MetroPunkPack/>
        </ScrollToTopRoute>

        <ScrollToTopRoute exact={true} path='/moderncities'>
          <ModernCitiesPack/>
        </ScrollToTopRoute>

        <ScrollToTopRoute exact={true} path='/scifi'>
          <ScifiGreeblesPack/>
        </ScrollToTopRoute>

        <ScrollToTopRoute exact={true} path='/motherboard'>
          <MotherboardPack/>
        </ScrollToTopRoute> */}
        
     </Switch>
     </Router>
     
    </div>
  );
}

export default App;
