import React,{ useEffect } from 'react'
import { Link, NavLink } from "react-router-dom";
import $ from "jquery";

const GreebleTownHeader = () => {

  useEffect(()=>{
    //SubMenu Dropdown Toggle
      if ($('.menu-area li.menu-item-has-children ul').length) {
        $('.menu-area .navigation li.menu-item-has-children').append('<div class="dropdown-btn"><span class="fas fa-angle-down"></span></div>');

      }

      //Mobile Nav Hide Show
      if ($('.mobile-menu').length) {

        var mobileMenuContent = $('.menu-area .push-menu').html();
        $('.mobile-menu .menu-box .menu-outer').append(mobileMenuContent);

        //Dropdown Button
        $('.mobile-menu li.menu-item-has-children .dropdown-btn').on('click', function () {
          $(this).toggleClass('open');
          $(this).prev('ul').slideToggle(500);
        });


        $('.menu-backdrop, .mobile-menu .close-btn').click (()=>{
          $('body').removeClass('mobile-menu-visible');
        })


        //Menu Toggle Btn
        $('.mobile-nav-toggler').on('click', function () {
          $('body').addClass('mobile-menu-visible');
        });
      }

  },[]);
  useEffect(()=>{
    $(window).on('scroll', function () {
      var scroll = $(window).scrollTop();
      if (scroll < 245) {
        $("#sticky-header").removeClass("sticky-menu");
        $('.scroll-to-target').removeClass('open');
    
      } else {
        $("#sticky-header").addClass("sticky-menu");
        $('.scroll-to-target').addClass('open');
      }
    });
    
  },[])
  
  return (
    <header className="header-style-four">
    
    <div id="sticky-header" className="header-four-wrap main-header menu-area">
    <div className="header-top-area s-header-top-area d-none d-lg-block">
      <div className="container custom-container-two">
        <div className="row align-items-center">
          <div className="col-lg-6 d-none d-lg-block">
            <div className="header-top-offer">
              <p>Available FREE Packs! </p>
              <span className="coming-time" data-countdown="2022/3/15" />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="header-social">
              <span>Follow us on :</span>
              <ul>
              
                {/* <li><a href="https://www.facebook.com/people/Greeble-Town/61550425273233/"><i className="fab fa-facebook-f" /></a></li> */}
                <li><a href="https://www.youtube.com/@GreebleTown" target="_blank" rel="noreferrer"><i className="fab fa-youtube" /></a></li>
                <li><a href="https://twitter.com/GreebleTown" target="_blank" rel="noreferrer"><i className="fab fa-twitter" /></a></li>
                <li><a href="https://www.instagram.com/greebletown/" target="_blank" rel="noreferrer"><i className="fab fa-instagram" /></a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
      <div className="container custom-container-two">
        <div className="row">
          <div className="col-12">
          <div className="mobile-nav-toggler"><i className="fas fa-bars" /></div>
            <div className="main-menu menu-style-two">
              <nav>
                <div className="logo">
                  <a href="/"><img src="assets/img/logo/logo.png" alt="Greeble Town Logo" /></a>
                </div>
                <div id="mobile-menu" className="navbar-wrap push-menu d-none d-lg-flex">
                <ul className="navigation">
                        <li ><NavLink  to="/">Home</NavLink>
                          {/* <ul className="submenu ">
                              <li ><Link to="/GreebleTown">GreebleTown</Link></li>
                              <li><Link to="/">Home One</Link></li>
                              <li><Link to="/index-2">Home Two</Link></li>
                              <li><Link to="/index-3">Home Three</Link></li>
                              <li ><Link to="/index-4">Home Four</Link></li>
                              <li><Link to="/index-5">Home Five</Link></li>
                              <li><Link to="/index-6">Home Six</Link></li>
                              <li><Link to="/index-7">Home Seven</Link></li>
                          </ul> */}
                        </li>
                        <li ><a href="/#about">About</a></li>
                        <li className=" menu-item-has-children"><a href="/#packs">Packs</a>
                          {/* <ul className="submenu">
                            <li><NavLink to="/industrial">INDUSTRIAL</NavLink></li>
                            <li><NavLink to="/metropunk">METROPUNK</NavLink></li>
                            <li><NavLink to="/moderncities">MODERN CITIES</NavLink></li>
                            <li><NavLink to="/scifi">SCI-FI GREEBLES</NavLink></li>
                            <li><NavLink to="/motherboard">MOTHERBOARD</NavLink></li>
                          </ul> */}
                        </li>
                        {/* <li ><NavLink to="/overview">Overview</NavLink></li>
                        <li ><NavLink to="/community">Community</NavLink></li>
                        <li ><NavLink to="/shop">Store</NavLink></li>
                        <li className=" menu-item-has-children"><NavLink to="/blogs">Blogs</NavLink>
                          <ul className="submenu">
                            <li><NavLink to="/blogs">News Page</NavLink></li>
                            <li><NavLink to="/blog-details">News Details</NavLink></li>
                          </ul>
                        </li>
                        <li><NavLink to="/contact">contact</NavLink></li> */}
                      </ul>
                </div>
                {/*  */}
              </nav>
            </div>
            <div className="mobile-menu">
                  <nav className="menu-box">
                    <div className="close-btn"><i className="fas fa-times" /></div>
                    <div className="nav-logo"><Link to="/"><img src="assets/img/logo/logo.png" alt="Greeble Town Logo" title='true' /></Link>
                    </div>
                    <div className="menu-outer">
                  
                    </div>
                    <div className="social-links">
                      <ul className="clearfix">
                      
                        <li><a href="https://twitter.com/GreebleTown" target="_blank" rel="noreferrer"><span className="fab fa-twitter" /></a></li>
                        <li><a href="https://www.youtube.com/@GreebleTown" target="_blank" rel="noreferrer"><i className="fab fa-youtube" /></a></li>
                        <li><a href="https://www.instagram.com/greebletown/" target="_blank" rel="noreferrer"><span className="fab fa-instagram" /></a></li>
                        {/* <li><a href="/#"><span className="fab fa-youtube" /></a></li> */}
                      </ul>
                    </div>
                  </nav>
                </div>
                <div className="menu-backdrop" />
          </div>
          {/* Modal Search */}
          {/* <div className="modal fade" id="search-modal" tabIndex={-1} role="dialog" aria-hidden="true">
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <form>
                  <input type="text" placeholder="Search here..." />
                  <button><i className="fa fa-search" /></button>
                </form>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  </header>
  )
}

export default GreebleTownHeader