import React, { useEffect, useState } from "react";
import Fade from 'react-reveal/Fade';
import LazyLoad from "react-lazyload";
// import Carousel from "better-react-carousel";
// import Carousel2 from "react-gallery-carousel";

// import LightGallery from "lightgallery/react";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";

// // import styles
// import "lightgallery/css/lightgallery.css";
// import "lightgallery/css/lg-zoom.css";
// import "lightgallery/css/lg-thumbnail.css";

// import plugins if you need
// import lgThumbnail from "lightgallery/plugins/thumbnail";
// import lgZoom from "lightgallery/plugins/zoom";

function SingleArea() {
  const images = [
    {
      original: "assets/img/slider/SciFi1.jpg",
      thumbnail: "assets/img/slider/SciFi1.jpg",
    },
    {
      original: "assets/img/slider/SciFi2.jpg",
      thumbnail: "assets/img/slider/SciFi2.jpg",
    },
    {
      original: "assets/img/images/scifi/scifi1.jpg",
      thumbnail: "assets/img/images/scifi/scifi1.jpg",
    },
    {
      original: "assets/img/images/scifi/scifi2.jpg",
      thumbnail: "assets/img/images/scifi/scifi2.jpg",
    },
    {
      original: "assets/img/images/scifi/scifi3.jpg",
      thumbnail: "assets/img/images/scifi/scifi3.jpg",
    },
    {
      original: "assets/img/images/scifi/scifi4.jpg",
      thumbnail: "assets/img/images/scifi/scifi4.jpg",
    },
    {
      original: "assets/img/images/scifi/scifi5.jpg",
      thumbnail: "assets/img/images/scifi/scifi5.jpg",
    },
    {
      original: "assets/img/images/scifi/scifi6.jpg",
      thumbnail: "assets/img/images/scifi/scifi6.jpg",
    },
    {
      original: "assets/img/images/scifi/scifi7.jpg",
      thumbnail: "assets/img/images/scifi/scifi7.jpg",
    },
    {
      original: "assets/img/images/scifi/scifi8.jpg",
      thumbnail: "assets/img/images/scifi/scifi8.jpg",
    },
    {
      original: "assets/img/images/scifi/scifiallpack.jpg",
      thumbnail: "assets/img/images/scifi/scifiallpack.jpg",
    },
    {
      original: "assets/img/images/scifi/SciFi.gif",
      thumbnail: "assets/img/images/scifi/SciFi.gif",
    },
    
    
  ];
  return (
    <div>
       <Fade ><ImageGallery infinite items={images} /></Fade>

      <section className="game-single-area pt-20 pb-20">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="">
                <div className="row">
                <div className="col-sm-6 mb-30">
                <div className='img-reveal'>
                  <img className="img1 img-fluid" src="assets/img/images/scifi_slide.png"  alt="Greeble Town SciFi"/>
                  <img className="img2 img-fluid" src="assets/img/images/scifi_slide2.png"  alt="Greeble Town SciFi"/>
                  </div>    </div>
                  <div className="col-sm-6">
                  <Fade >
                    <p>
                    Introducing the Free Sci-Fi Greeble Asset Pack! Elevate your
                sci-fi creations to new heights with this FREE
                collection of high-quality 3D greebles. Dive into the world of
                immersive and ultra-imaginative sci-fi environments, where
                intricate pipes, towering machinery, and alien landscapes await
                your creative touch. With PBR textures, lightweight designs, and
                scatter-friendly, your sci-fi dreams are limited only
                by your imagination. Download now and let the universe of
                possibilities unfold before you!
                    </p>
                    </Fade>
                    <Fade >
                    <div className="centergt">
                      <a
                        target="_blank" rel="noreferrer"
                        href="https://blendermarket.com/products/free-future-scratch-sci-fi-sketches---scifi-kitbash-greeble-assets"
                        className="sticky-footer-button btn btn-style-two"
                      >
                        DOWNLOAD NOW
                      </a>
                    </div>
                    </Fade>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default SingleArea;
