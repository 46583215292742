import React,{ useEffect } from 'react'
import WOW from "wowjs";

import Fade from "react-reveal/Fade";


function AboutInner() {
  const settings = {
    dots: false,
    infinite: true,
    speed: 100,
    
    autoplay: true,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll:1,
    center:false,}
  useEffect(()=>{
    new WOW.WOW({
      boxClass: 'wow',
      animateClass: 'animated',
      offset: 0,
      mobile: false,
      live: true,
      loop:Infinity,
    }).init();
  
    },[])
  return (
	<section id="about" className="inner-about-area fix pt-50">
        <div className="container">
          <div className="row align-items-center">
            
            <div className="col-xl-6 col-lg-6 order-0 order-lg-2 about-photo  wow fadeInRight"  data-wow-delay=".3s" alt="">
              
             
              <div className='img-reveal'>
                  <img className="img1 img-fluid wow fadeInRight" data-wow-delay=".3s" alt="Greeble Town About Us" src="assets/img/images/inner_about_img01.png"  />
                  <img className="img2 img-fluid wow fadeInRight" data-wow-delay=".3s" alt="Greeble Town About Us" src="assets/img/images/inner_about_img02.png"  />
                  <img src="assets/img/images/why_greebletown.png" className="img3 img-fluid wow fadeInRight" data-wow-delay=".3s" alt=""  />
               
                  </div>     
                 
                
                
                {/* <img src="assets/img/images/inner_about_img02.png" className="wow fadeInLeft" data-wow-delay=".6s" alt="" />
                <img src="assets/img/images/inner_about_img03.png" className="wow fadeInUp" data-wow-delay=".6s" alt="" /> */}
              
            </div>
            <div className="col-xl-6 col-lg-6 gt-about">
              <Fade big cascade><div className="section-title title-style-three mb-10">
                
                <h2>ABOUT <span>US</span></h2>
              </div>
              <div className="inner-about-content">

                <p><span style={{fontWeight:"bold"}}>GREEBLE TOWN</span> is the ultimate destination for premium sci-fi and hard-surface kitbash greeble assets.</p>
                <p>We specialize in crafting the finest sci-fi and hard-surface-themed <span style={{fontWeight:"bold"}}>3D greeble assets</span>. Our <span style={{fontWeight:"bold"}}>PBR textured</span> assets are intentionally versatile, efficient, and lightweight, designed for easy scattering, making it effortless to create breathtaking,seemingly complex and expansive scenes with unparalleled ease. At Greeble Town, we live and breathe our passion for crafting top-tier, premium-quality yet cost-effective sci-fi and hard-surface kitbash greeble assets. Our mission is to empower your creative journey with assets that ignite your imagination and elevate your projects to new heights.</p>
                {/* <p>We craft PBR textured themed assets that are lightweight and purposefully designed for easy scattering. These assets can be effortlessly scattered and arranged to construct breathtaking scenes with unparalleled ease. Join us in our quest to transform your creative visions into reality. Together, let's build massive worlds that defy limits and spark inspiration.</p>
 */}

                <div className="pt-30 pb-30 centergt">
                    <a  href="https://blendermarket.com/creators/greebletown" className="btn btn-style-two">CONTACT US</a>
                  </div>
                
              </div></Fade>
            </div>
          </div>
        </div>
        <div className="inner-about-shape"><img style={{opacity:'0.2',filter:"grayscale(1)"}} src="assets/img/slider/Industrial_2.jpg" alt="Greeble Town Industrial" /></div>

        
      </section>
      
  )
}

export default AboutInner