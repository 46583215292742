import React, { useEffect, useState } from "react";
import Fade from 'react-reveal/Fade';
import LazyLoad from "react-lazyload";
// import Carousel from "better-react-carousel";
// import Carousel2 from "react-gallery-carousel";

// import LightGallery from "lightgallery/react";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";

// // import styles
// import "lightgallery/css/lightgallery.css";
// import "lightgallery/css/lg-zoom.css";
// import "lightgallery/css/lg-thumbnail.css";

// import plugins if you need
// import lgThumbnail from "lightgallery/plugins/thumbnail";
// import lgZoom from "lightgallery/plugins/zoom";

function SingleArea() {
  const images = [
    {
      original: "assets/img/slider/Starforge2.jpg",
      thumbnail: "assets/img/slider/Starforge2.jpg",
    },
    {
      original: "assets/img/slider/Starforge1.jpg",
      thumbnail: "assets/img/slider/Starforge1.jpg",
    },
    {
      original: "assets/img/images/starforge/starforge (1).jpg",
      thumbnail: "assets/img/images/starforge/starforge (1).jpg",
    },
    {
      original: "assets/img/images/starforge/starforge (2).jpg",
      thumbnail: "assets/img/images/starforge/starforge (2).jpg",
    },
    {
      original: "assets/img/images/starforge/starforge (3).jpg",
      thumbnail: "assets/img/images/starforge/starforge (3).jpg",
    },
   
    {
      original: "assets/img/images/starforge/starforge (4).jpg",
      thumbnail: "assets/img/images/starforge/starforge (4).jpg",
    },
    {
      original: "assets/img/images/starforge/starforge (5).jpg",
      thumbnail: "assets/img/images/starforge/starforge (5).jpg",
    },
    {
      original: "assets/img/images/starforge/starforge (6).jpg",
      thumbnail: "assets/img/images/starforge/starforge (6).jpg",
    },
    {
      original: "assets/img/images/starforge/starforge (7).jpg",
      thumbnail: "assets/img/images/starforge/starforge (7).jpg",
    },
  ];
  return (
    <div>
      <Fade ><ImageGallery infinite items={images} /></Fade>

      <section className="game-single-area pt-20 pb-20">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="">
                <div className="row">
                <div className="col-sm-6 mb-30">
                <div className='img-reveal'>
                  <img className="img1 img-fluid" src="assets/img/images/starforge_slide_1.png"  alt="Spacestation Base 3D Asset Greebles"/>
                  <img className="img2 img-fluid" src="assets/img/images/starforge_slide_2.png"  alt="Spacestation Base3D Asset Greebles"/>
                  </div>    </div>
                  <div className="col-sm-6">
                  <Fade >
                    <p>
                    Set out on an interstellar odyssey with Starforge Nexus Spacestation Kit. This modular asset pack unlocks limitless variations, allowing you to craft enormous spacestations with ease. Featuring PBR textures and an array of tiling textures, build majestic classic space stations, intricate greeble space colonies, or expansive spacetowns and settlements. Explore the cosmos like never before, with endless possibilities from your asset browser.
                    </p>
                    </Fade>
                    <Fade >
                    <div className="centergt">
                      <a
                        target="_blank" rel="noreferrer"
                        href="https://blendermarket.com/products/starforge-nexus---spacestation-kitbash-greeble-assets"
                        className="sticky-footer-button btn btn-style-two"
                      >
                        PURCHASE NOW
                      </a>
                    </div>
                    </Fade>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default SingleArea;
