import React from 'react';
import { Link } from "react-router-dom";

function Footer() {
  return (
	   <footer>
      <div className="footer-top footer-bg mb-0 pb-0">
          {/* newsletter-area */}
          <div className="newsletter-area">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  
                </div>
              </div>
            </div>
          </div>
          {/* newsletter-area-end */}
          <div className="container">
            <div className="row justify-content-between mt-50">
              <div className="col-xl-3 col-lg-4 col-md-6">
                <div className="footer-widget mb-50">
                  <div className="footer-logo mb-35">
                    <a href="/"><img src="assets/img/logo/logo.png" alt="" /></a>
                  </div>
                  <div className="footer-text">
                    <p>The <span style={{fontWeight:"700"}}>BEST</span> place for premium sci-fi & hardsurface kitbash greeble assets.</p>
                    {/* <div className="footer-contact">
                      <ul>
                        <li><i className="fas fa-map-marker-alt" /> <span>Address : </span>PO Box W75 Street
                          lan West new queens</li>
                        <li><i className="fas fa-headphones" /> <span>Phone : </span>+24 1245 654 235</li>
                        <li><i className="fas fa-envelope-open" /><span>Email : </span>info@exemple.com</li>
                      </ul>
                    </div> */}
                  </div>
                </div>
              </div>
              <div className="col-xl-2 col-lg-3 col-sm-6">
                <div className="footer-widget mb-50">
                  <div className="fw-title mb-35">
                    <h5>Products</h5>
                  </div>
                  <div className="fw-link">
                    <ul>
                      <li><a  href="/#packs">Industrial</a></li>
                      <li><a  href="/#packs">Metropunk</a></li>
                      <li><a  href="/#packs">Modern Cities</a></li>
                      <li><a  href="/#packs">SciFi</a></li>
                      <li><a  href="/#packs">Motherboard</a></li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-2 col-lg-3 col-sm-6">
                <div className="footer-widget mb-50">
                  <div className="fw-title mb-35">
                    <h5>Need Help?</h5>
                  </div>
                  <div className="fw-link">
                    <ul>
                      <li><a  href="https://support.blendermarket.com/article/42-terms-of-use-site">Terms &amp; Conditions</a></li>
                      {/* <li><a  href="/#">Privacy Policy</a></li> */}
                      <li><a  href="https://support.blendermarket.com/article/22-refund-policy">Refund Policy</a></li>
                      {/* <li><a  href="/#">Affiliate</a></li> */}
                      <li><a  href="https://blendermarket.com/creators/greebletown">Contact Us</a></li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-md-6">
                <div className="footer-widget mb-50">
                  <div className="fw-title mb-35">
                    <h5>Follow us</h5>
                  </div>
                  <div className="footer-social">
                    <ul>
                    <li><a href="https://twitter.com/GreebleTown" target="_blank" rel="noreferrer"><span className="fab fa-twitter" /></a></li>
                        <li><a href="https://www.youtube.com/@GreebleTown" target="_blank" rel="noreferrer"><i className="fab fa-youtube" /></a></li>
                        <li><a href="https://www.instagram.com/greebletown/" target="_blank" rel="noreferrer"><span className="fab fa-instagram" /></a></li>
                    </ul>
                  </div>
                </div>
                {/* <div className="footer-widget mb-50">
                  <div className="fw-title mb-35">
                    <h5>Newsletter Sign Up</h5>
                  </div>
                  <div className="footer-newsletter">
                    <form >
                      <input type="text" placeholder="Enter your email" />
                      <button><i className="fas fa-rocket" /></button>
                    </form>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
          <div className="footer-fire"><img src="assets/img/images/footer_fire.png" alt="Greeble Town" /></div>
          <div className="footer-fire footer-fire-right"><img src="assets/img/images/footer_fire.png" alt="Greeble Town" /></div>
        </div>
        
        <div className="copyright-wrap">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-6">
                <div className="copyright-text">
                  <p>Copyright © 2022 <a  href="/#">Greeble Town</a> All Rights Reserved.</p>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 d-none d-md-block">
                {/* <div className="payment-method-img text-right">
                  <img src="assets/img/images/card_img.png" alt="img" />
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </footer>
  )
}

export default Footer;
