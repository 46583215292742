import React, { useEffect, useState } from "react";
import Fade from 'react-reveal/Fade';
import LazyLoad from "react-lazyload";
// import Carousel from "better-react-carousel";
// import Carousel2 from "react-gallery-carousel";

// import LightGallery from "lightgallery/react";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";

// // import styles
// import "lightgallery/css/lightgallery.css";
// import "lightgallery/css/lg-zoom.css";
// import "lightgallery/css/lg-thumbnail.css";

// import plugins if you need
// import lgThumbnail from "lightgallery/plugins/thumbnail";
// import lgZoom from "lightgallery/plugins/zoom";

function SingleArea() {
    const images = [
        {
            original: "assets/img/slider/Grid (1).jpg",
            thumbnail: "assets/img/slider/Grid (1).jpg",
        },
        {
            original: "assets/img/slider/Grid (2).jpg",
            thumbnail: "assets/img/slider/Grid (2).jpg",
        },

        {
            original: "assets/img/images/gridarmada/gridarmada (16).jpg",
            thumbnail: "assets/img/images/gridarmada/gridarmada (16).jpg",
        },
        {
            original: "assets/img/images/gridarmada/gridarmada (2).jpg",
            thumbnail: "assets/img/images/gridarmada/gridarmada (2).jpg",
        },
        {
            original: "assets/img/images/gridarmada/gridarmada (1).jpg",
            thumbnail: "assets/img/images/gridarmada/gridarmada (1).jpg",
        },
        {
            original: "assets/img/images/gridarmada/gridarmada (3).jpg",
            thumbnail: "assets/img/images/gridarmada/gridarmada (3).jpg",
        },

        {
            original: "assets/img/images/gridarmada/gridarmada (5).jpg",
            thumbnail: "assets/img/images/gridarmada/gridarmada (5).jpg",
        },
        {
            original: "assets/img/images/gridarmada/gridarmada (4).jpg",
            thumbnail: "assets/img/images/gridarmada/gridarmada (4).jpg",
        },
        {
            original: "assets/img/images/gridarmada/gridarmada (1).png",
            thumbnail: "assets/img/images/gridarmada/gridarmada (1).png",
        },
        {
            original: "assets/img/images/gridarmada/gridarmada (6).jpg",
            thumbnail: "assets/img/images/gridarmada/gridarmada (6).jpg",
        },

        {
            original: "assets/img/images/gridarmada/gridarmada (7).jpg",
            thumbnail: "assets/img/images/gridarmada/gridarmada (7).jpg",
        },

        {
            original: "assets/img/images/gridarmada/gridarmada (8).jpg",
            thumbnail: "assets/img/images/gridarmada/gridarmada (8).jpg",
        },
        {
            original: "assets/img/images/gridarmada/gridarmada (9).jpg",
            thumbnail: "assets/img/images/gridarmada/gridarmada (9).jpg",
        },
        {
            original: "assets/img/images/gridarmada/gridarmada (10).jpg",
            thumbnail: "assets/img/images/gridarmada/gridarmada (10).jpg",
        },

        {
            original: "assets/img/images/gridarmada/gridarmada (11).jpg",
            thumbnail: "assets/img/images/gridarmada/gridarmada (11).jpg",
        },
        {
            original: "assets/img/images/gridarmada/gridarmada (12).jpg",
            thumbnail: "assets/img/images/gridarmada/gridarmada (12).jpg",
        },
        {
            original: "assets/img/images/gridarmada/gridarmada (13).jpg",
            thumbnail: "assets/img/images/gridarmada/gridarmada (13).jpg",
        },
        {
            original: "assets/img/images/gridarmada/gridarmada (14).jpg",
            thumbnail: "assets/img/images/gridarmada/gridarmada (14).jpg",
        },

        {
            original: "assets/img/images/gridarmada/gridarmada (15).jpg",
            thumbnail: "assets/img/images/gridarmada/gridarmada (15).jpg",
        },

        
        // {
        //     original: "assets/img/images/gridarmada/Metro1.gif",
        //     thumbnail: "assets/img/images/gridarmada/Metro1.gif",
        // },
        // {
        //     original: "assets/img/images/gridarmada/Metro2.gif",
        //     thumbnail: "assets/img/images/gridarmada/Metro2.gif",
        // },

    ];
    return (
        <div>
            <Fade ><ImageGallery infinite items={images} /></Fade>

            <section className="game-single-area pt-20 pb-20">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="">
                                <div className="row">
                                    <div className="col-sm-6 mb-30">
                                        <div className='img-reveal'>
                                            <img className="img1 img-fluid" src="assets/img/images/grid_slide1.png" alt="Grid Armada Military Base 3D Asset Greebles" />
                                            <img className="img2 img-fluid" src="assets/img/images/grid_slide2.png" alt="Grid Armada Military Base 3D Asset Greebles" />
                                        </div>    </div>
                                    <div className="col-sm-6">
                                        <Fade >
                                            <p>
                                            Embark into the realm of classic sci-fi warfare with Grid Armada Military Base, an adequately detailed yet lightweight 3D asset pack. Immerse your projects in meticulously crafted military 3D greeble structures designed with weapons-grade ambition. Dive into limitless creative possibilities for crafting captivating military base scenes. Enrich your 3D asset library effortlessly—Grid Armada stands as your ultimate digital arsenal, offering immersive sci-fi experiences beyond imagination.
                                            </p>
                                        </Fade>
                                        <Fade >
                                            <div className="centergt">
                                                <a
                                                    target="_blank" rel="noreferrer"
                                                    href="https://blendermarket.com/products/grid-armada-military-base---classic-military-structures-kitbash-greeble-assets"
                                                    className="sticky-footer-button btn btn-style-two"
                                                >
                                                    PURCHASE NOW
                                                </a>
                                            </div>
                                        </Fade>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default SingleArea;
