import React from 'react'
import IndexGreebleTown from '../../components/index/greebletown/IndexGreebleTown';

function GreebleTown() {

  return (

  <IndexGreebleTown/>
  )
}

export default GreebleTown
