import React, { useEffect, useState } from "react";
import Fade from 'react-reveal/Fade';
import LazyLoad from "react-lazyload";
// import Carousel from "better-react-carousel";
// import Carousel2 from "react-gallery-carousel";

// import LightGallery from "lightgallery/react";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";

// // import styles
// import "lightgallery/css/lightgallery.css";
// import "lightgallery/css/lg-zoom.css";
// import "lightgallery/css/lg-thumbnail.css";

// import plugins if you need
// import lgThumbnail from "lightgallery/plugins/thumbnail";
// import lgZoom from "lightgallery/plugins/zoom";

function SingleArea() {
  const images = [
    {
      original: "assets/img/slider/industrial_2.jpg",
      thumbnail: "assets/img/slider/industrial_2.jpg",
    },
    {
      original: "assets/img/slider/industrial_1.jpg",
      thumbnail: "assets/img/slider/industrial_1.jpg",
    },
    {
      original: "assets/img/images/industrial/industrial1.jpg",
      thumbnail: "assets/img/images/industrial/industrial1.jpg",
    },
    {
      original: "assets/img/images/industrial/industrial2.jpg",
      thumbnail: "assets/img/images/industrial/industrial2.jpg",
    },
    {
      original: "assets/img/images/industrial/industrial3.jpg",
      thumbnail: "assets/img/images/industrial/industrial3.jpg",
    },
    {
      original: "assets/img/images/industrial/industrial4.jpg",
      thumbnail: "assets/img/images/industrial/industrial4.jpg",
    },
    {
      original: "assets/img/images/industrial/industrial5.jpg",
      thumbnail: "assets/img/images/industrial/industrial5.jpg",
    },
    {
      original: "assets/img/images/industrial/industrial6.jpg",
      thumbnail: "assets/img/images/industrial/industrial6.jpg",
    },
    {
      original: "assets/img/images/industrial/industrial8.jpg",
      thumbnail: "assets/img/images/industrial/industrial8.jpg",
    },
    {
      original: "assets/img/images/industrial/industrial7.jpg",
      thumbnail: "assets/img/images/industrial/industrial7.jpg",
    },
    {
      original: "assets/img/images/industrial/industrialscatter.gif",
      thumbnail: "assets/img/images/industrial/industrialscatter.gif",
    },
  ];
  return (
    <div style={{bacgkgroundImage:"url'assets/img/images/inner_about_img01.png'"}}>
      <Fade ><ImageGallery infinite items={images} /></Fade>

      <section className="game-single-area pt-20 pb-20">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="">
                <div className="row">
                  
                  <div className="col-sm-6 mb-30">
                <div className='img-reveal'>
                  <img className="img1 img-fluid" src="assets/img/images/industrial_slide.png"  alt="Greeble Town Industrial"/>
                  <img className="img2 img-fluid" src="assets/img/images/industrial_slide2.png"  alt="Greeble Town Indsutrial"/>
                  </div>    </div>
                  <div className="col-sm-6">
                  <Fade >
                    <p>
                      Elevate your creative projects to an industrial
                      masterpiece with Greeble Town's Industrial Pack.
                      Lightweight and easy to integrate, they allow you to
                      effortlessly scatter and greeble your scenes, bringing
                      heavy industrial worlds to life. From intricate pipes and
                      towering tanks to sprawling distillation units and
                      powerful plants, our assets offer limitless possibilities.
                      Immerse your audience in the gritty, industrial beauty of
                      your creations and redefine what's possible. Let the
                      industrial revolution of your imagination begin!
                    </p>
                    </Fade>
                    <Fade >
                    <div className="centergt">
                      <a
                        target="_blank" rel="noreferrer"
                        href="https://blendermarket.com/products/grunge-factory-decayed-assemblies---industrial-kitbash-greeble-assets"
                        className="sticky-footer-button btn btn-style-two"
                      >
                        PURCHASE NOW
                      </a>
                    </div>
                    </Fade>
                  </div>
                </div>
              </div>
              
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default SingleArea;
