import React, { useEffect, useState } from "react";
import Fade from 'react-reveal/Fade';
import LazyLoad from "react-lazyload";
// import Carousel from "better-react-carousel";
// import Carousel2 from "react-gallery-carousel";

// import LightGallery from "lightgallery/react";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";

// // import styles
// import "lightgallery/css/lightgallery.css";
// import "lightgallery/css/lg-zoom.css";
// import "lightgallery/css/lg-thumbnail.css";

// import plugins if you need
// import lgThumbnail from "lightgallery/plugins/thumbnail";
// import lgZoom from "lightgallery/plugins/zoom";

function SingleArea() {
  const images = [
    {
      original: "assets/img/slider/ModernCities2.jpg",
      thumbnail: "assets/img/slider/ModernCities2.jpg",
    },
    {
      original: "assets/img/slider/ModernCities1.jpg",
      thumbnail: "assets/img/slider/ModernCities1.jpg",
    },
    {
      original: "assets/img/images/moderncities/moderncities01.jpg",
      thumbnail: "assets/img/images/moderncities/moderncities01.jpg",
    },
    {
      original: "assets/img/images/moderncities/moderncities1.jpg",
      thumbnail: "assets/img/images/moderncities/moderncities1.jpg",
    },
    {
      original: "assets/img/images/moderncities/moderncitiespack.jpg",
      thumbnail: "assets/img/images/moderncities/moderncitiespack.jpg",
    },
    {
      original: "assets/img/images/moderncities/moderncities2.jpg",
      thumbnail: "assets/img/images/moderncities/moderncities2.jpg",
    },
    {
      original: "assets/img/images/moderncities/moderncities02.jpg",
      thumbnail: "assets/img/images/moderncities/moderncities02.jpg",
    },
    {
      original: "assets/img/images/moderncities/moderncities3.jpg",
      thumbnail: "assets/img/images/moderncities/moderncities3.jpg",
    },
    {
      original: "assets/img/images/moderncities/moderncities4.jpg",
      thumbnail: "assets/img/images/moderncities/moderncities4.jpg",
    },
    {
      original: "assets/img/images/moderncities/moderncities5.jpg",
      thumbnail: "assets/img/images/moderncities/moderncities5.jpg",
    },
    {
      original: "assets/img/images/moderncities/moderncities03.jpg",
      thumbnail: "assets/img/images/moderncities/moderncities03.jpg",
    },
    {
      original: "assets/img/images/moderncities/moderncities6.jpg",
      thumbnail: "assets/img/images/moderncities/moderncities6.jpg",
    },
    {
      original: "assets/img/images/moderncities/ModernCities.gif",
      thumbnail: "assets/img/images/moderncities/ModernCities.gif",
    },
    
    
    
  ];
  return (
    <div>
       <Fade ><ImageGallery infinite items={images} /></Fade>

      <section className="game-single-area pt-20 pb-20">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="">
                <div className="row">
                <div className="col-sm-6 mb-30">
                <div className='img-reveal'>
                  <img className="img1 img-fluid" src="assets/img/images/moderncities_slide.png"  alt="Greeble Town Skyline Ukiyo"/>
                  <img className="img2 img-fluid" src="assets/img/images/moderncities_slide1.png"  alt="Greeble Town Skyline Ukiyo"/>
                  </div>    </div>
                  <div className="col-sm-6">
                  <Fade >
                    <p>
                    Take your creative modern metropolis projects to the next level with the Modern Cities Buildings Asset Pack.. This comprehensive pack
                includes a wide variety of high-quality 3D models of modern city
                buildings, from skyscrapers to apartment complexes to office
                buildings. All of the models are PBR-textured and come with
                multiple materials and textures.The pack includes a variety of different building types, so you can create a diverse and interesting cityscape.
                    </p>
                    </Fade>
                    <Fade >
                    <div className="centergt">
                      <a
                        target="_blank" rel="noreferrer"
                        href="https://blendermarket.com/products/skyline-ukiyo-modern-metropolis---modern-cities-kitbash-greeble-assets"
                        className="sticky-footer-button btn btn-style-two"
                      >
                        PURCHASE NOW
                      </a>
                    </div>
                    </Fade>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default SingleArea;
