import React from 'react';
import Slider from 'react-slick';
import Fade from "react-reveal/Fade";
import Slide from "react-reveal/Slide";
import LazyLoad from "react-lazyload";


function HomeSlider() {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    
    autoplay: true,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll:1,
    center:false,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          SlidesToScroll: 1,
        }
      },
      {
        breakpoint: 575,
        settings: {
          sidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        }
      }
    ]
  }

  // useEffect(()=>{
	// new WOW.WOW({
  //   boxClass: 'wow',
	// 	animateClass: 'animated',
	// 	offset: 0,
	// 	mobile: false,
	// 	live: true,
  //   loop:Infinity,
  // }).init();

  // },[])

  return (


<section className="slider-area">
            <Slider className="slider-active" style={{  }} {...settings}>
                <LazyLoad>
              <div className="single-slider slider-bg slider-style-two">
                <div className="container-fluid container-full-padding">
                  <div className="row">
                    <div className="col-xl-6 col-lg-7 col-md-11">
                      <div className="slider-content ">
                        <Fade up delay={.4000}>
                        <h6>WELCOME TO</h6>
                        </Fade>
                        
                        <Fade up delay={.5000}> 
                        <img className="mb-40 mt-40 col-sm-12 home-logo" style={{position:"relative",width:"450px"}}src="assets/img/images/Greeble Logo.png" alt="Greeble Town" />
                        </Fade>
                        <Fade up delay={.5000}>
                        <h3 >The <span style={{fontWeight:"700"}}>BEST</span> place for premium sci-fi & hardsurface kitbash greeble assets.</h3>
                        </Fade >
                        
                        {/* <Fade up delay={.8000}>
                        <a href="/#" className="btn btn-style-two">READ MORE</a>
                        </Fade> */}
                      </div>
                      <div style={{height:"120px"}}>&nbsp;</div>
                    </div>
                  </div>
                </div>
                
                {/* <Slide right delay={.4000}>
                <div className="slider-img"><img src="assets/img/slider/slider_img01.png" alt="" /></div>
                </Slide>
               <Slide left delay={.4000}>
               <div className="slider-img slider-img2 wow slideInRightS"><img src="assets/img/slider/slider_img02.png" alt="" /></div>
               </Slide>
                <div className="slider-circle-shape"><img src="assets/img/slider/slider_logo.png" alt="" className="rotateme" /></div> */}
              </div>
              
              
              
              </LazyLoad>
            </Slider>
            
          </section>
  )
}

export default HomeSlider