import React, { useEffect, useState } from "react";
import Fade from 'react-reveal/Fade';
import LazyLoad from "react-lazyload";
// import Carousel from "better-react-carousel";
// import Carousel2 from "react-gallery-carousel";

// import LightGallery from "lightgallery/react";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";

// // import styles
// import "lightgallery/css/lightgallery.css";
// import "lightgallery/css/lg-zoom.css";
// import "lightgallery/css/lg-thumbnail.css";

// import plugins if you need
// import lgThumbnail from "lightgallery/plugins/thumbnail";
// import lgZoom from "lightgallery/plugins/zoom";

function SingleArea() {
    const images = [
        {
            original: "assets/img/slider/EmpireSands.jpg",
            thumbnail: "assets/img/slider/EmpireSands.jpg",
        },
        {
            original: "assets/img/slider/EmpireSands2.jpg",
            thumbnail: "assets/img/slider/EmpireSands2.jpg",
        },

        {
            original: "assets/img/images/empiresands/empiresands (1).jpg",
            thumbnail: "assets/img/images/empiresands/empiresands (1).jpg",
        },
        {
            original: "assets/img/images/empiresands/empiresands (2).jpg",
            thumbnail: "assets/img/images/empiresands/empiresands (2).jpg",
        },
        {
            original: "assets/img/images/empiresands/empiresands (3).jpg",
            thumbnail: "assets/img/images/empiresands/empiresands (3).jpg",
        },
        {
            original: "assets/img/images/empiresands/empiresands (4).jpg",
            thumbnail: "assets/img/images/empiresands/empiresands (4).jpg",
        },

        {
            original: "assets/img/images/empiresands/empiresands (6).jpg",
            thumbnail: "assets/img/images/empiresands/empiresands (6).jpg",
        },
        {
            original: "assets/img/images/empiresands/empiresands (7).jpg",
            thumbnail: "assets/img/images/empiresands/empiresands (7).jpg",
        },
        {
            original: "assets/img/images/empiresands/empiresands (8).jpg",
            thumbnail: "assets/img/images/empiresands/empiresands (8).jpg",
        },
  
        
        {
            original: "assets/img/images/empiresands/empiresands (1).png",
            thumbnail: "assets/img/images/empiresands/empiresands (1).png",
        },
        {
            original: "assets/img/images/empiresands/empiresands (2).png",
            thumbnail: "assets/img/images/empiresands/empiresands (2).png",
        },
        {
            original: "assets/img/images/empiresands/empiresands (3).png",
            thumbnail: "assets/img/images/empiresands/empiresands (3).png",
        },
        {
            original: "assets/img/images/empiresands/empiresands (4).png",
            thumbnail: "assets/img/images/empiresands/empiresands (4).png",
        },
        {
            original: "assets/img/images/empiresands/empiresands (5).png",
            thumbnail: "assets/img/images/empiresands/empiresands (5).png",
        },
        {
            original: "assets/img/images/empiresands/empiresands (6).png",
            thumbnail: "assets/img/images/empiresands/empiresands (6).png",
        },
        {
            original: "assets/img/images/empiresands/empiresands (7).png",
            thumbnail: "assets/img/images/empiresands/empiresands (7).png",
        },
        {
            original: "assets/img/images/empiresands/empiresands (8).png",
            thumbnail: "assets/img/images/empiresands/empiresands (8).png",
        },
        {
            original: "assets/img/images/empiresands/empiresands (9).png",
            thumbnail: "assets/img/images/empiresands/empiresands (9).png",
        },
        

    ];
    return (
        <div>
            <Fade ><ImageGallery infinite items={images} /></Fade>

            <section className="game-single-area pt-20 pb-20">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="">
                                <div className="row">
                                    <div className="col-sm-6 mb-30">
                                        <div className='img-reveal'>
                                            <img className="img1 img-fluid" src="assets/img/images/empiresands_slide (1).png" alt="Desert Dune SciFi Base 3D Asset Greebles" />
                                            <img className="img2 img-fluid" src="assets/img/images/empiresands_slide (2).png" alt="Desert Dune SciFi Base 3D Asset Greebles" />
                                        </div>    </div>
                                    <div className="col-sm-6">
                                        <Fade >
                                            <p>
                                            Embark on a journey to desert and dune-themed sci-fi fantasy worlds with 'Empire of the Sands,' a captivating collection of lightweight and scatter-friendly assets inspired by desert kingdoms and dunescape sci-fi aesthetics. Explore modular buildings, featuring clay or limestone architecture, meticulously crafted to bring your desert landscapes to life. From towering sandstone or limestone structures to alien like desert buildings, this pack offers endless possibilities for creators seeking to evoke the allure and mystery of desert empires. Create expansive environments with ease using our scatter-friendly assets.
                                            </p>
                                        </Fade>
                                        <Fade >
                                            <div className="centergt">
                                                <a
                                                    target="_blank" rel="noreferrer"
                                                    href="https://blendermarket.com/products/empire-of-the-sands---desert-structures-kitbash-greeble-assets"
                                                    className="sticky-footer-button btn btn-style-two"
                                                >
                                                    PURCHASE NOW
                                                </a>
                                            </div>
                                        </Fade>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default SingleArea;
