import React, { useEffect, useState } from "react";
import Fade from 'react-reveal/Fade';
import LazyLoad from "react-lazyload";
// import Carousel from "better-react-carousel";
// import Carousel2 from "react-gallery-carousel";

// import LightGallery from "lightgallery/react";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";

// // import styles
// import "lightgallery/css/lightgallery.css";
// import "lightgallery/css/lg-zoom.css";
// import "lightgallery/css/lg-thumbnail.css";

// import plugins if you need
// import lgThumbnail from "lightgallery/plugins/thumbnail";
// import lgZoom from "lightgallery/plugins/zoom";

function SingleArea() {
  const images = [
    {
      original: "assets/img/slider/Metropunk2.jpg",
      thumbnail: "assets/img/slider/Metropunk2.jpg",
    },
    {
      original: "assets/img/slider/Metropunk1.jpg",
      thumbnail: "assets/img/slider/Metropunk1.jpg",
    },
    {
      original: "assets/img/images/metropunk/MetroPunk (2).jpg",
      thumbnail: "assets/img/images/metropunk/MetroPunk (2).jpg",
    },
    {
      original: "assets/img/images/metropunk/MetroPunk (1).jpg",
      thumbnail: "assets/img/images/metropunk/MetroPunk (1).jpg",
    },
    {
      original: "assets/img/images/metropunk/MetroPunk (3).jpg",
      thumbnail: "assets/img/images/metropunk/MetroPunk (3).jpg",
    },
   
    {
      original: "assets/img/images/metropunk/MetroPunk (5).jpg",
      thumbnail: "assets/img/images/metropunk/MetroPunk (5).jpg",
    },
    {
      original: "assets/img/images/metropunk/Metro1.gif",
      thumbnail: "assets/img/images/metropunk/Metro1.gif",
    },
    {
      original: "assets/img/images/metropunk/Metro2.gif",
      thumbnail: "assets/img/images/metropunk/Metro2.gif",
    },
    
  ];
  return (
    <div>
      <Fade ><ImageGallery infinite items={images} /></Fade>

      <section className="game-single-area pt-20 pb-20">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="">
                <div className="row">
                <div className="col-sm-6 mb-30">
                <div className='img-reveal'>
                  <img className="img1 img-fluid" src="assets/img/images/metropunk_slide.png"  alt="Greeble Town Metropunk"/>
                  <img className="img2 img-fluid" src="assets/img/images/metropunk_slide2.png"  alt="Greeble Town Metropunk"/>
                  </div>    </div>
                  <div className="col-sm-6">
                  <Fade >
                    <p>
                    Discover an unprecedented cyberpunk world! Immerse yourself in a neon-lit, dystopian future using our MetroPunk Cyber City assets. This meticulously crafted collection offers an immersive journey into a high-tech, radiant universe. Experience futuristic cityscapes, mind-bending visuals, and cyberpunk-inspired lights brought to life in astonishingly lightweight assets. Elevate your creative projects with this unique cyberpunk experience.
                    </p>
                    </Fade>
                    <Fade >
                    <div className="centergt">
                      <a
                        target="_blank" rel="noreferrer"
                        href="https://blendermarket.com/products/metropunk-neon-skyscrapers---cyberpunk-kitbash-greeble-assets"
                        className="sticky-footer-button btn btn-style-two"
                      >
                        PURCHASE NOW
                      </a>
                    </div>
                    </Fade>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default SingleArea;
