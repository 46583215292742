import React, { useEffect, useState } from "react";
import Fade from 'react-reveal/Fade';
import LazyLoad from "react-lazyload";
// import Carousel from "better-react-carousel";
// import Carousel2 from "react-gallery-carousel";

// import LightGallery from "lightgallery/react";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";

// // import styles
// import "lightgallery/css/lightgallery.css";
// import "lightgallery/css/lg-zoom.css";
// import "lightgallery/css/lg-thumbnail.css";

// import plugins if you need
// import lgThumbnail from "lightgallery/plugins/thumbnail";
// import lgZoom from "lightgallery/plugins/zoom";

function SingleArea() {
  const images = [
    {
      original: "assets/img/slider/MoBo1.jpg",
      thumbnail: "assets/img/slider/MoBo1.jpg",
    },
    {
      original: "assets/img/slider/MoBo2.jpg",
      thumbnail: "assets/img/slider/MoBo2.jpg",
    },
    {
      original: "assets/img/images/mobo/Mobo1.jpg",
      thumbnail: "assets/img/images/mobo/Mobo1.jpg",
    },
    {
      original: "assets/img/images/mobo/Mobo2.jpg",
      thumbnail: "assets/img/images/mobo/Mobo2.jpg",
    },
    {
      original: "assets/img/images/mobo/Mobo3.jpg",
      thumbnail: "assets/img/images/mobo/Mobo3.jpg",
    },
    {
      original: "assets/img/images/mobo/Mobo4.jpg",
      thumbnail: "assets/img/images/mobo/Mobo4.jpg",
    },
    {
      original: "assets/img/images/mobo/Mobo 5.jpg",
      thumbnail: "assets/img/images/mobo/Mobo 5.jpg",
    },
    {
      original: "assets/img/images/mobo/Mobo 6.jpg",
      thumbnail: "assets/img/images/mobo/Mobo 6.jpg",
    },
    
    
    
  ];
  return (
    <div>
       <Fade ><ImageGallery infinite items={images} /></Fade>

      <section className="game-single-area pt-20 pb-20">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="">
                <div className="row">
                <div className="col-sm-6 mb-30">
                <div className='img-reveal'>
                  <img className="img1 img-fluid" src="assets/img/images/mobo_slide.png" alt="Greeble Town Motherboard" />
                  <img className="img2 img-fluid" src="assets/img/images/mobo_slide2.png" alt="Greeble Town Motherboard" />
                  </div>    </div>
                  <div className="col-sm-6">
                  <Fade >
                    <p>
                    Unlock more of your digital creativity with our Free
                Motherboard Circuit Greebles Pack! Dive deep into the intricate
                world of circuitry and supercharge your sci-fi projects with
                abstract microprocessors, data conduits, and motherboard
                aesthetics. These lightweight, PBR-textured greebles are
                designed for scattering, opening up endless possibilities for
                your abstract renders. Download now and take your digital
                artistry to electrifying new heights.
                    </p>
                    </Fade>
                    <Fade >
                    <div className="centergt">
                      <a
                        target="_blank" rel="noreferrer"
                        href="https://blendermarket.com/products/free-binary-frame-computer-mainboards---abstract-motherboard-kitbash-greeble-assets"
                        className="sticky-footer-button btn btn-style-two"
                      >
                        DOWNLOAD NOW
                      </a>
                    </div>
                    </Fade>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default SingleArea;
